<template>

  <div>
    <!-- Filters -->
    <app-collapse>
      <app-collapse-item title="Filters">
        <organization-list-filters
          :status-filter.sync="statusFilter"
          :status-options="statusOptions"
        />
      </app-collapse-item>
    </app-collapse>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0 mt-2"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search Name / Slug"
              />
              <b-button
                variant="primary"
                :to="{ name: 'add-organization' }"
              >
                <span class="text-nowrap">Add Organization</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchOrganizationList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'view-organization', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <span>
            <feather-icon
              v-b-tooltip.hover.top="'Edit Organization'"
              class="btn-link cursor-pointer"
              icon="EditIcon"
              @click="editRecord(data)"
            /> |
            <feather-icon
              v-b-tooltip.hover.top="'View Organization'"
              class="btn-link cursor-pointer"
              icon="EyeIcon"
              @click="viewRecord(data)"
            /> |
            <feather-icon
              v-b-tooltip.hover.top="'Delete Organization'"
              class="btn-link cursor-pointer"
              icon="TrashIcon"
              @click="confirmDeleteRecord(data)"
            />
          </span>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalOrganizationList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BFormInput, BLink, BMedia, BPagination, BRow, BTable, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import store from '@/store'
import organizationStoreModule from '../organizationStoreModule'
import useOrganizationList from './useOrganizationList'
import OrganizationListFilters from './OrganizationListFilters.vue'

export default {
  components: {
    OrganizationListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    AppCollapse,
    AppCollapseItem,

    vSelect,
    /* eslint-disable */
            ToastificationContent,
            /* eslint-enable */
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const REFERRAL_APP_STORE_MODULE_NAME = 'app-organizations'

    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(REFERRAL_APP_STORE_MODULE_NAME,
        organizationStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME)
    })

    const statusOptions = []

    const {
      fetchOrganizationList,
      tableColumns,
      perPage,
      currentPage,
      totalOrganizationList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      statusFilter,
    } = useOrganizationList()

    return {
      // Sidebar
      fetchOrganizationList,
      tableColumns,
      perPage,
      currentPage,
      totalOrganizationList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Extra Filters
      statusOptions,
      statusFilter,
    }
  },
  created() {
    this.$http.get('/list/getOrganizationStatus')
      .then(res => {
        this.statusOptions = res.data.data
      })
      .catch(error => {
        // console.log(error)
      })
  },
  methods: {
    editRecord(data) {
      this.$router.push(`/edit/organization/${data.item.id}`).catch(() => {})
    },
    viewRecord(data) {
      this.$router.push(`/view/organization/${data.item.id}`).catch(() => {})
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: 'Are you sure?',
        text: `You want to delete ${data.item.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteRecord(data.item.id)
        }
      })
    },
    deleteRecord(id) {
      const self = this
      this.$store.dispatch('app-organizations/removeOrganization', id)
        .then(res => {
          if (res.data.success) {
            self.refetchData()
            self.$toast({
              component: ToastificationContent,
              props: {
                title: 'Organization Deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Organization deleted successfully',
              },
            }, {
              timeout: 3000,
            })
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: 'Organization Deletion failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: 'Please try again after sometime',
              },
            }, {
              timeout: 3000,
            })
          }
        })
        .catch(err => {
          // console.error(err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
    @import '@core/scss/vue/libs/vue-select.scss';

    .per-page-selector {
        width: 90px;
    }
</style>
